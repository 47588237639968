<template>
  <div id="lastSessionContainer">
    <template v-if="!lastSessionLoader">
      <div class="dateContainer">
        <div class="date" v-if="date">
          <div id="monthDay">
            <p>
              {{date.day}}
              {{date.month}}
            </p>
          </div>
          <div id="year">
            <p>{{date.year}}</p>
          </div>
        </div>
      </div>
      <p id="title">{{ $t('last_session.title') | capitalize }}</p>
      <div id="statsWrapper" v-if="stats">
        <template v-for="(stat, index) in stats">
          <div class="statContainer" :key="stat.id">
            <div class="statTitle">
              <p>{{getStatTitle(index) | capitalize}}</p>
            </div>
            <div class="stat">
              <div class="statImg">
                <img :src="getStatIcon(index)" />
              </div>
              <div>{{parseStat(stat, index, $i18n.locale)}}</div>
              <div v-if="isPersonalBest() && (index == 'distance')" class="statImg" id="pbdIcon">
                <img src="../assets/icons/ic_personalBest.png" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <LoadingDots :black="true" v-else class="loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { icons } from "../constants";
import { parseStat } from "../parsers";

const LoadingDots = () => import("./LoadingDots");

export default {
  name: "LastSession",
  components: { LoadingDots },
  data() {
    return {
      stats: {},
      date: {}
    };
  },
  computed: {
    ...mapState({
      lastSession: state => state.lastSession,
      personalBest: state => state.personalBest,
      lastSessionLoader: state => state.lastSessionLoader
    }),
    unitMultiplier: function() {
      return this.$i18n.locale == 'en' ? 1.0936 : 1;
    }
  },
  mounted() {
    const { date, ...stats } = this.lastSession;
    this.stats = { ...stats };
    this.date = date;
  },
  methods: {
    getStatTitle(stat) {
      return this.$t(`last_session.${stat}_label`);
    },
    getStatIcon(index) {
      const img = require.context("../assets/icons/", false, /\.png$/);
      return img("./" + icons[index] + ".png");
    },
    shouldShowLine(index) {
      return !(index === "restingTime" || index === "speed");
    },
    isPersonalBest() {
      return this.lastSession.distance == this.personalBest;
    },
    parseStat
  }
};
</script>

<style scoped>
#lastSessionContainer {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  box-shadow: 0px 1px 2px 0px #ccd0db;
  background-color: white;
}
.dateContainer {
  position: relative;
}
.date {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 7%;
  margin-top: -1.2vh;
  width: 8rem;
  background-color: #030d2c;
  color: white;
  font-size: 1rem;

  padding: 0.2rem 0.5rem;
}
#monthDay,
#year {
  display: flex;
  justify-content: space-around;
  width: 48%;
}
#monthDay {
  font-weight: 600;
}
#title {
  color: black;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 1.5rem;
  margin-bottom: 0;
  text-align: left;
  width: calc(100% - 13rem);
}
#statsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.875rem 0;
  padding: 0 1.5rem;
}
.statContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 0 45%;
  width: 100%;
  height: 5.3rem;
  min-height: fit-content;
  border-bottom-width: 1px;
  border-bottom-color: #ccd0db;
  border-bottom-style: solid;
}
.statContainer p {
  margin: 0;
}
.statContainer:nth-last-child(-n + 2) {
  border-bottom: none;
}
.stat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 1.5rem;
  color: #030d2c;
  font-weight: 500;
}
.statTitle {
  font-size: 1rem;
  text-align: left;
}
.statImg {
  display: flex;
  align-items: center;
  margin-right: 1vw;
  justify-content: center;
  height: 2.5vh;
  width: 2.5vw;
}
.statImg > img {
  max-width: 100%;
  max-height: 100%;
}
#pbdIcon {
  height: 100%;
  margin-left: 10px;
  transform: rotate(20deg);
}
.invisible {
  display: none;
}

@media screen and (max-width: 1024px) {
  #lastSessionContainer {
    margin-top: -5rem;
  }
  .statImg {
    height: 2rem;
    width: fit-content;
  }
  #title {
    padding-left: 6%;
    margin: 2rem 0 1rem 0;
  }
  .date {
    right: 0;
    margin-top: 0;
    width: 6rem;
  }
  #statsWrapper {
    padding: 0 6%;
  }
  .statContainer {
    height: 6rem;
  }
  .statTitle {
    height: 45%;
    display: flex;
    align-items: center;
  }
  .statTitle p {
    margin: 0;
  }
  #pbdIcon {
    height: 2rem;
    width: auto;
    margin-left: 10px;
    transform: rotate(20deg);
  }
}
@media screen and (max-width: 321px) {
  .date {
    height: 5rem;
    width: 2rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
}
</style>